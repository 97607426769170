var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "perfil-corretora" }, [
    _c(
      "div",
      { staticClass: "breadcrumb mb-6" },
      [
        _c("breadcrumb", {
          attrs: { title: "Corretor", actualPage: "Detalhe Corretor" }
        })
      ],
      1
    ),
    !!_vm.cadastro.Id
      ? _c(
          "div",
          { staticClass: "w-full content p-4 perfil-corretora-tabs" },
          [
            _c(
              "vs-tabs",
              { attrs: { color: "warning" } },
              [
                _vm.profileHelpers.checkPermission([
                  _vm.profileHelpers.menuFuncionalitiesEnum.GERENCIAR_CORRETORA,
                  _vm.profileHelpers.menuFuncionalitiesEnum.VISUALIZAR_CORRETORA
                ])
                  ? [
                      _c(
                        "vs-tab",
                        {
                          staticClass: "p-4",
                          attrs: { label: "Dados Gerais" }
                        },
                        [
                          _c("dados-cadastrais", {
                            attrs: { corretor: _vm.cadastro }
                          })
                        ],
                        1
                      ),
                      _c(
                        "vs-tab",
                        { attrs: { label: "Dados Financeiros" } },
                        [
                          _c("dados-bancarios", {
                            attrs: { cadastro: _vm.cadastro },
                            on: { updateFinancialData: _vm.updateFinancialData }
                          })
                        ],
                        1
                      ),
                      _c(
                        "vs-tab",
                        { attrs: { label: "Sócios/ Administradores PEP" } },
                        [
                          _c("dados-pep", {
                            attrs: {
                              QSAs: _vm.socios.lista,
                              cadastro: _vm.cadastro
                            }
                          })
                        ],
                        1
                      )
                    ]
                  : _vm._e(),
                _vm.profileHelpers.checkPermission([
                  _vm.profileHelpers.menuFuncionalitiesEnum.VISUALIZAR_USUARIOS,
                  _vm.profileHelpers.menuFuncionalitiesEnum.GERENCIAR_USUARIOS
                ])
                  ? _c(
                      "vs-tab",
                      { attrs: { label: "Dados Usuários" } },
                      [
                        _c("dados-usuarios", {
                          attrs: {
                            cadastro: _vm.cadastro,
                            podeGerenciar: _vm.profileHelpers.checkPermission([
                              _vm.profileHelpers.menuFuncionalitiesEnum
                                .GERENCIAR_USUARIOS
                            ])
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.profileHelpers.checkPermission([
                  _vm.profileHelpers.menuFuncionalitiesEnum
                    .VISUALIZAR_DOCUMENTOS
                ])
                  ? _c(
                      "vs-tab",
                      { attrs: { label: "Documentos" } },
                      [_c("documentos")],
                      1
                    )
                  : _vm._e()
              ],
              2
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }