<template>
  <div class="documentos pt-8 p-4">
    <div class="vx-row">
      <h3 class="vx-col w-full font-semibold mb-4">
        Documentos
      </h3>

      <div class="vx-col w-full">
        <vs-alert class="mb-5" :active="alertProposta" color="warning">
          Adicione os documentos para realizar o cadastro de propostas.
        </vs-alert>
      </div>
    </div>
    <div class="vx-row" v-if="listaDocumentos.length > 0">
      <div class="vx-col w-full">
        <template>
          <vs-table :data="listaDocumentos" class="vx-col w-full font-semibold">
            <template slot="thead">
              <vs-th>
                Status
              </vs-th>
              <vs-th>
                Nome do documento
              </vs-th>
              <vs-th>
                Descrição
              </vs-th>
              <vs-th>
                Visualizar
              </vs-th>
              <vs-th>
                Upload
              </vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="data[indextr].Status" class="pl-6">
                  <vx-tooltip :text="data[indextr].StatusDescription">
                    <span
                      class="material-icons"
                      :class="getTextColor(data[indextr].Status)"
                    >
                      {{ data[indextr].Status | statusDocumento }}
                    </span>
                  </vx-tooltip>
                </vs-td>

                <vs-td :data="data[indextr].Name">
                  {{ data[indextr].Name }}
                </vs-td>

                <vs-td :data="data[indextr].Description">
                  {{ data[indextr].Description }}
                </vs-td>

                <vs-td>
                  <vs-button
                    size="large"
                    type="flat"
                    icon="description"
                    @click="
                      openModalListarDocumentos(
                        data[indextr].Name,
                        data[indextr].Id
                      )
                    "
                  />
                </vs-td>

                <vs-td>
                  <vs-button
                    :disabled="data[indextr].Submitted"
                    size="large"
                    type="flat"
                    icon="cloud_upload"
                    @click="modalUploadOpen(data[indextr])"
                  />
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </template>
      </div>
    </div>
    <div
      class="vx-row mt-5"
      v-if="possuiDocumentacaoPendente || possuiDocumentParaEnvio"
    >
      <div class="vx-col w-full text-right">
        <vs-button
          color="primary"
          :disabled="!podeSubmeter"
          @click="submeterDocumentos(listaDocumentos)"
        >
          <span class="font-semibold">Submeter para análise</span>
        </vs-button>
      </div>
    </div>
    <!-- Visualizar -->
    <vs-popup title="" :active.sync="modalListarDocumentos">
      <h3 class="mb-10 ml-3">Upload de Documento - {{ uploadDocName }}</h3>
      <vs-list v-if="listaDocumentosDetalhes.length > 0">
        <vs-list-item
          :title="documento.OriginalFileName"
          v-for="(documento, index) in listaDocumentosDetalhes"
          :key="index"
        >
          <template v-if="documento.Name">
            <a
              class="vs-button vs-button-primary vs-button-flat includeIcon includeIconOnly large"
              @click="baixarDocuemnto(documento.UniqueId)"
              v-storage
              :download="documento.OriginalFileName"
            >
              <span class="material-icons">
                cloud_download
              </span>
            </a>
          </template>
        </vs-list-item>
      </vs-list>
      <p v-else class="mb-4 text-center">Carregando lista de documentos</p>
    </vs-popup>

    <!-- Upload -->
    <vs-popup title="" :active.sync="modalUpload">
      <h3 class="text-center">Upload de Documento - {{ uploadDocName }}</h3>
      <div class="mb-5">
        <div class="con-img-upload">
          <div class="img-upload" v-if="arquivo.base64">
            <button class="btn-x-file" type="button" @click="removeFile()">
              <i translate="no" class="material-icons notranslate">
                clear
              </i>
            </button>

            <h4 class="text-archive" style="width:100%;     font-size: 12px;">
              <i translate="no" class="material-icons notranslate">
                description
              </i>
              <p>
                <b>{{ arquivo.Filename }}</b>
              </p>
            </h4>
          </div>
          <div class="con-input-upload" v-else>
            <input
              data-v-480def7c=""
              type="file"
              @change="getFiles($event)"
            /><span data-v-480def7c="" class="text-input">
              Clique aqui para selecionar o arquivo. </span
            ><span
              data-v-480def7c=""
              class="input-progress"
              style="width: 0%;"
            ></span
            ><button
              data-v-480def7c=""
              disabled="disabled"
              type="button"
              title="Upload"
              class="btn-upload-all vs-upload--button-upload"
            >
              <i
                data-v-480def7c=""
                translate="translate"
                class="material-icons notranslate"
              >
                cloud_upload
              </i>
            </button>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col w-full  text-right">
            <vs-button
              v-if="arquivo.base64"
              @click.native="enviarDocumento(idDocumento)"
              class="ml-8"
              color="success"
            >
              <span class="font-semibold pr-2 pl-2">
                Enviar documentos
              </span>
            </vs-button>
            <vs-button
              v-else
              disabled
              @click.native="enviarDocumento(idDocumento)"
              class="ml-8"
              color="success"
            >
              <span class="font-semibold pr-2 pl-2">
                Enviar documentos
              </span>
            </vs-button>
          </div>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import Upload from "@/components/az-upload/AzUpload.vue";
import { mapGetters, mapMutations, mapActions } from "vuex";
import instance from "@/axios";
const getBase64 = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.split(",")[1]);
    reader.onerror = error => reject(error);
  });
};

export default {
  name: "documentos",
  components: { Upload },
  data() {
    return {
      arquivo: {
        Filename: null,
        FileExtension: null,
        base64: null
      },
      alertProposta: "",
      listaDocumentos: [],
      modalUpload: false,
      uploadDocumentoController: {},
      uploadDocName: "",
      listaDocumentosDetalhes: [],
      files: [],
      modalListarDocumentos: false,
      idDocumento: 0,
      documentosParaEnvio: undefined
    };
  },
  computed: {
    ...mapGetters("documentos-corretora", ["documentos"]),
    possuiDocumentacaoPendente: {
      get() {
        const pendentes = this.listaDocumentos.filter(item => {
          return item.Status != 3 && item.Status != 1;
        });

        return pendentes.length > 0;
      }
    },
    possuiDocumentParaEnvio: {
      get() {
        const envio = this.listaDocumentos.filter(item => {
          return item.Submitted == false;
        });
        return envio.length > 0;
      }
    },

    podeSubmeter() {
      const docs = this.listaDocumentos.filter(doc => {
        if (doc.StatusName == "Pendente" || doc.StatusName == "Recusado")
          return doc;
      });

      return (docs || []).length === 0;
    }
  },
  async mounted() {
    await this.carregarDocumentos();
    if (
      this.$route.query.activeTabIndex &&
      this.$route.query.activeTabIndex === "4"
    ) {
      this.alertProposta = true;
    }
  },
  methods: {
    ...mapActions("documentos-corretora", [
      "getDocumentos",
      "getDocumentoPorId",
      "uploadDocumento",
      "getUrlFileDownload",
      "submeterDotumentoParaAnalise"
    ]),
    ...mapMutations("documentos-corretora", ["updateDocumentos"]),
    veriryFile(name) {
      let toLowerCase = name.toLowerCase();
      let extension = toLowerCase.split(".").pop();
      console.log("arquivo: ", extension);
      let types = [
        "png",
        "jpeg",
        "jpg",
        "xlsx",
        "xls",
        "rtf",
        "docx",
        "pptx",
        "odt",
        "pdf"
      ];
      let result = null;
      return types.includes(extension);
    },
    removeFile(index) {
      this.arquivo.base64 = null;
      this.arquivo.FileExtension = null;
      this.arquivo.Filename = null;
    },

    getFiles(e, boleto, index) {
      this.$vs.loading();
      setTimeout(() => {
        this.$vs.loading.close();
      }, 1000);
      let fileSize = e.target.files[0].size;
      let fileMb = fileSize / 1024 ** 2;
      if (fileMb > 25) {
        this.$onpoint.errorModal(
          "Arquivo muito grande, escolha arquivos até 25mb e tente novamente!"
        );
      } else {
        if (this.veriryFile(e.target.files[0].name)) {
          getBase64(e.target.files[0]).then(result => {
            (this.arquivo.Filename = e.target.files[0].name.split(".")[0]),
              (this.arquivo.FileExtension = e.target.files[0].name
                .split(".")
                .pop()),
              (this.arquivo.base64 = result);
          });
        } else {
          this.$onpoint.errorModal("Arquivo inválido, tente novamente!");
        }
      }
    },
    openModalListarDocumentos(nameDoc, id) {
      this.modalListarDocumentos = true;
      this.uploadDocName = nameDoc;
      this.obterDocumento(id);
    },

    modalUploadOpen(doc) {
      this.arquivo = {
        Filename: null,
        FileExtension: null,
        base64: null
      };
      this.modalUpload = true;
      this.uploadDocName = doc.Name;
      this.idDocumento = doc.Id;
    },

    baixarDocuemnto(uniqueId) {
      this.$onpoint.loading(() => {
        return this.getUrlFileDownload(uniqueId)
          .then(doc => {
            window.open(doc, "_blank");
            this.$onpoint.notification({ message: "Download com sucesso." });
          })
          .catch(ex => {
            this.$onpoint.errorModal();
          });
      });
    },

    submeterDocumentos() {
      this.$onpoint.loading(() => {
        return this.submeterDotumentoParaAnalise()
          .then(data => {
            this.$onpoint.notification({
              message: "Documentos enviados com sucesso."
            });
            this.listaDocumentos = data;
          })
          .catch(error => {
            this.$onpoint.errorModal(error.response.data.Errors);
          });
      });
    },

    obterDocumento(id) {
      this.listaDocumentosDetalhes = [];
      return this.getDocumentoPorId(id).then(response => {
        if (response.length === 0) {
          this.listaDocumentosDetalhes = [
            { OriginalFileName: "Nenhum documento cadastrado nessa categoria" }
          ];
        } else {
          this.listaDocumentosDetalhes = response;
        }
        return this.listaDocumentosDetalhes;
      });
    },

    carregarDocumentos() {
      this.$onpoint.loading(() => {
        return this.getDocumentos().then(response => {
          this.listaDocumentos = response;
          this.documentosParaEnvio = response.find(
            d => d.RequestDocumentStatusId != 1
          );
        });
      });
    },

    enviarDocumento(idDocumento) {
      this.$vs.loading();

      return instance({
        method: "post",
        url: "/api/Archive/UploadBlob",
        data: {
          FileExtension: this.arquivo.FileExtension,
          Filename: this.arquivo.Filename,
          Base64Data: this.arquivo.base64
        }
      })
        .then(result => {
          let data = {
            extension: this.arquivo.FileExtension,
            name: result.data.Response.Filename,
            relativePath: result.data.Response.Filename,
            uniqueIdentifier: result.data.Response.UniqueId
          };
          const request = {
            BrokerRequestDocumentId: idDocumento,
            Files: [data]
          };
          return this.uploadDocumento(request)
            .then(() => {
              this.$vs.loading.close();
              this.arquivo = {
                Filename: null,
                FileExtension: null,
                base64: null
              };
              this.modalUpload = false;
              this.carregarDocumentos();
              const pendentes = this.listaDocumentos.filter(item => {
                return item.Status == 2 || item.Status == 4;
              });

              if (pendentes.length == 1) {
                this.$onpoint.successModal(
                  'Upload com sucesso, clique em "Submeter para análise", para enviar os documentos para seguradora!'
                );
              } else {
                this.$onpoint.successModal("Upload com sucesso!");
              }
            })
            .catch(errors => {
              this.$vs.loading.close();
              this.$onpoint.errorModal(errors.response.data.Errors);
            });
        })
        .catch(errors => {
          this.$vs.loading.close();
          this.$onpoint.errorModal(errors.response.data.Errors);
        });
    },

    getTextColor(id) {
      if (id === 1) return "text-success";
      if (id === 2) return "text-warning";
      if (id === 3) return "text-primary";
      if (id === 4) return "text-danger";
    }
  }
};
</script>
<style lang="scss">
.upload {
  width: 228px;
  margin: 0 auto;
}
</style>
