var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dados-bancarios pt-8 p-4" },
    [
      _vm._m(0),
      _vm._l(_vm.socios, function(socio, index) {
        return _c(
          "div",
          { key: index, staticClass: "vx-row mt-5" },
          [
            socio
              ? [
                  _c(
                    "div",
                    { staticClass: "vx-col w-1/2" },
                    [
                      _c("vs-input", {
                        staticClass: "w-full",
                        attrs: {
                          label: "Nome",
                          disabled: "true",
                          value: socio.Name,
                          size: "large"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "vx-col w-1/2" },
                    [
                      _c("p", [_vm._v("CPF")]),
                      _c("the-mask", {
                        staticClass: "w-full input-the-mask",
                        attrs: {
                          mask: ["###.###.###-##"],
                          disabled: "true",
                          masked: true,
                          value: socio.CpfCnpj
                        }
                      })
                    ],
                    1
                  )
                ]
              : _vm._e()
          ],
          2
        )
      })
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "vx-row" }, [
      _c("h3", { staticClass: "vx-col w-full font-semibold" }, [
        _vm._v("\n      Sócios/ Administradores PEP\n    ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }